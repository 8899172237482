import { Event, useStore } from '@mirai/data-sources';
import { useLocale } from '@mirai/locale';
import { Button, Icon, styles, Text, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import { PriceDetails } from './components/Item/components';
import { getLabelTaxes } from './helpers';
import { L10N } from './Rates.l10n';
import * as style from './Rates.module.css';
import { debounce } from '../../helpers';
import { EVENT, ICON } from '../helpers';

const Footer = ({
  cart = [],
  clubRate,
  dataSource: { taxesType } = {},
  extras,
  multiRoom,
  occupation = [],
  onSubmit = () => {},
  ...others
}) => {
  const { currencyFormat, translate } = useLocale();
  const { value: { currency } = {} } = useStore();
  const refContainer = useRef();

  const [busy, setBusy] = useState(false);

  useEffect(() => {
    const items = cart.filter((item) => item !== undefined);

    Event.publish(EVENT.MODAL_VISIBLE, { displayName: 'Mirai:Core:Rates:Footer', visible: items.length > 0 });

    const content = refContainer?.current;
    if (!content || typeof ResizeObserver === 'undefined') return;

    const observer = new ResizeObserver(
      debounce(() =>
        Event.publish(EVENT.FOOTER_HEIGHT_CHANGE, { height: items.length ? content.offsetHeight : undefined }),
      ),
    );
    observer.observe(content);

    return () => observer.unobserve(content);
  }, [cart]);

  useEffect(() => !extras && setBusy(false), [extras]);

  useEffect(() => !clubRate && setBusy(false), [clubRate]);

  const items = cart.filter((item) => item !== undefined);
  const totalPrice = cart.reduce((value, { price = 0 } = {}) => value + price, 0);
  const valid = items.length === occupation.length;

  const handleSubmit = () => {
    setBusy(true);
    onSubmit();
  };

  const visible = items.length > 0;
  const currencyFormatProps = { currency, maximumFractionDigits: 0, minimumFractionDigits: 0 };
  const { testId } = others;

  return (
    <View
      {...others}
      ref={refContainer}
      className={styles(style.footer, visible && style.visible, others.className)}
      aria-hidden={visible ? 'false' : 'true'}
    >
      <View>
        <View className={style.wrapper} wide>
          {items.map(({ amount = 1, id, name: boardName, price, roomName } = {}, index) => {
            const guests = occupation[index].reduce((total, { amount } = {}) => total + amount, 0);

            return (
              <View row key={`${id}${index}`} className={style.cartItem}>
                {multiRoom && (
                  <View row className={style.room}>
                    <Icon value={guests === 1 ? ICON.PERSON : guests === 2 ? ICON.GROUP : ICON.GROUPS} />
                    <Text small>{guests}</Text>
                  </View>
                )}

                <View className={style.description}>
                  <Text bold action className={style.description}>
                    {`${roomName} `}
                    {multiRoom && <Text light small>{` | ${boardName}`}</Text>}
                  </Text>
                  {!multiRoom && (
                    <Text light small>
                      {boardName}
                    </Text>
                  )}
                </View>

                <Text bold action>
                  {currencyFormat({ ...currencyFormatProps, value: amount * price })}
                </Text>

                {!multiRoom && (
                  <Button busy={busy} disabled={!valid} large onPress={handleSubmit}>
                    {translate(L10N.ACTION_RESERVE)}
                  </Button>
                )}
              </View>
            );
          })}
        </View>
      </View>

      {multiRoom && (
        <View row className={[style.wrapper, style.summary]}>
          <View className={style.totalPrice}>
            <Text bold headline level={2}>
              {currencyFormat({ ...currencyFormatProps, value: totalPrice })}
            </Text>

            {!!taxesType && (
              <Text light small>
                {translate(getLabelTaxes(taxesType))}
              </Text>
            )}

            <PriceDetails cart={cart} metrics="RATES:FOOTER" taxesType={taxesType} />
          </View>

          <Button
            busy={busy}
            disabled={!valid}
            large
            onPress={handleSubmit}
            aria-label={translate(L10N.ACTION_RESERVE)}
            testId={testId ? `${testId}-reserve` : undefined}
          >
            {translate(L10N.ACTION_RESERVE)}
          </Button>
        </View>
      )}
    </View>
  );
};

Footer.displayName = 'Mirai:Core:Rates:Footer';

Footer.propTypes = {
  cart: PropTypes.arrayOf(PropTypes.shape({})),
  clubRate: PropTypes.bool,
  dataSource: PropTypes.shape({}),
  extras: PropTypes.arrayOf(PropTypes.shape({})),
  multiRoom: PropTypes.bool,
  occupation: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        ages: PropTypes.arrayOf(PropTypes.number),
        amount: PropTypes.number,
        type: PropTypes.number,
      }),
    ),
  ),
  onSubmit: PropTypes.func,
};

export { Footer };
