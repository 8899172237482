import { ERROR, TAXES_TYPE, VALIDATE_ERROR } from '../../Core.constants';

export const L10N = {
  ACTION_CLUB_VIEW_CONDITIONS: { id: 'finder.action.club_view_conditions' },
  ACTION_IMMERSIVE_BOOKING: { id: 'finder.label.immersive_booking' },
  ACTION_REFINE_SEARCH: { id: 'common.action.refine_search' },
  ACTION_RESERVE: { id: 'common.action.reserve' },
  ACTION_SHOW_ON_MAP: { id: 'finder.action.show_on_map' },
  ACTION_VIEW_MORE: { id: 'common.action.view_more' },

  LABEL_ACCOMMODATION_TYPE: { id: 'common.label.accommodation_type' },
  LABEL_ALTERNATIVE_HOTEL_SUGGESTIONS: { id: 'finder.label.alternative_hotel_suggestions' },
  LABEL_AVAILABLE_ALTERNATIVES: { id: 'finder.label.available_alternatives' },
  LABEL_CLUB_BENEFITS: { id: 'finder.label.club_benefits' },
  LABEL_CONTACT_INFO: { id: 'finder.label.contact_info' },
  LABEL_EXCLUDES_TAXES_FEES: { id: 'finder.label.excludes_taxes_fees' },
  LABEL_FROM: { id: 'common.label.from' },
  LABEL_INCLUDES_TAXES_FEES: { id: 'finder.label.includes_taxes_fees' },
  LABEL_MEMBER_DISCOUNT: { id: 'finder.label.member_discount' },
  LABEL_MEMBER_EXCLUSIVE: { id: 'finder.label.member_exclusive' },
  LABEL_NIGHTS: { id: 'common.label.nights' },
  LABEL_NO_AVAILABILITY_CALENDAR: { id: 'common.label.no_availability_calendar' },
  LABEL_NO_AVAILABILITY_OCCUPATION: { id: 'common.label.no_availability_occupation' },
  LABEL_SELECT: { id: 'common.label.select' },
  LABEL_TAXES_FEES: {
    [TAXES_TYPE.ALL_EXCLUDED]: { id: 'finder.label.excludes_taxes_fees' },
    [TAXES_TYPE.ALL_INCLUDED]: { id: 'finder.label.includes_taxes_fees' },
    [TAXES_TYPE.FEES_EXCLUDED]: { id: 'finder.label.excludes_fees' },
    [TAXES_TYPE.FEES_INCLUDED_TAXES_EXCLUDED]: { id: 'finder.label.includes_fees_excludes_taxes' },
    [TAXES_TYPE.FEES_INCLUDED]: { id: 'finder.label.includes_fees' },
    [TAXES_TYPE.TAXES_EXCLUDED]: { id: 'finder.label.excludes_taxes' },
    [TAXES_TYPE.TAXES_INCLUDED_FEES_EXCLUDED]: { id: 'finder.label.includes_taxes_excludes_fees' },
    [TAXES_TYPE.TAXES_INCLUDED]: { id: 'finder.label.includes_taxes' },
  },
  LABEL_YEARS_OLD: { id: 'common.label.years_old' },

  NOTIFICATION_ERROR: {
    [ERROR.NOT_AVAILABLE]: { id: 'common.notification.error_not_available' },
    [ERROR.PARAMETER_ERROR]: { id: 'common.notification.error_parameter' },
    [ERROR.UNKNOWN]: { id: 'common.notification.error_unknown' },
  },
  NOTIFICATION_META_RATE_FOUND: { id: 'finder.notification.meta_rate_found' },
  NOTIFICATION_META_RATE_NOT_FOUND: { id: 'finder.notification.meta_rate_not_found' },
  NOTIFICATION_PROMOCODE_NOT_FOUND: { id: 'finder.notification.promocode_not_found' },
  NOTIFICATION_ROOM_NOT_FOUND: { id: 'finder.notification.room_not_found' },
  NOTIFICATION_OFFER_NOT_FOUND: { id: 'finder.notification.offer_not_found' },
  NOTIFICATION_VALIDATE: {
    [VALIDATE_ERROR.NOT_ENOUGH_ROOMS]: { id: 'finder.notification.not_enough_rooms' },
    [VALIDATE_ERROR.NOT_ENOUGH_ROOMS_FOR_OFFER]: { id: 'finder.notification.not_enough_rooms_for_offer' },
    [VALIDATE_ERROR.NO_SELECTED_ROOMS]: { id: 'finder.notification.no_selected_rooms' },
    [VALIDATE_ERROR.EXTRA_CHANGED]: { id: 'finder.notification.extra_changed' },
  },
};
