body {
  padding: 0;
  margin: 0;
  overflow-x: hidden;
}

[data-mirai-component='rates'] {
  background-color: var(--mirai-ui-content-background);
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - var(--mirai-ui-space-XXL));
  z-index: 1;
}

.finder .wrapper {
  align-content: center;
  align-items: center;
  justify-content: center;
  max-width: var(--mirai-ui-breakpoint-content);
  position: relative;
  width: 100%;
}

.finder {
  align-items: center;
}

.finder.sticky {
  position: sticky;
  top: 0;
}

.progress {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: var(--mirai-ui-layer-M);
}

.container {
  align-items: center;
  position: relative;
  width: 100vw;
  z-index: var(--mirai-ui-layer-S);
}

.container .wrapper {
  margin: 0 auto;
  max-width: var(--mirai-ui-breakpoint-content);
  width: 100%;
}

.container .extraContent {
  gap: var(--mirai-ui-space-M);
}

.container > .content {
  width: 100%;
}

/* -- <Header> ------------------------------------------------------------------------------------------------------ */
.header {
  flex-wrap: wrap;
  gap: var(--mirai-ui-space-XS);
  justify-content: flex-end;
}

.header.sticky {
  position: sticky;
  top: 0;
}

.header .switch {
  margin-bottom: 0;
  align-self: center;
}

.header .wide {
  width: 100%;
}

/* -- <RoomSelector> ------------------------------------------------------------------------------------------------ */
.roomSelector {
  flex: 1;
  gap: var(--mirai-ui-space-XS);
  width: fit-content;
}

.roomSelector > * {
  min-width: max-content;
}

.roomSelector .separator {
  margin: 0 var(--mirai-ui-space-XXS);
  opacity: 0.33;
}

.separator.active {
  color: var(--mirai-ui-base);
}

.separator.disabled {
  color: var(--mirai-ui-content);
}

.separator.secondary {
  color: var(--mirai-ui-accent);
}

/* -- <Footer> ------------------------------------------------------------------------------------------------------ */
.footer {
  background-color: var(--mirai-ui-base);
  bottom: 0;
  box-shadow: inset 0 1px 0 var(--mirai-ui-content-border), var(--mirai-ui-modal-shadow);
  position: fixed;
  transform: translateY(100%);
  transition: transform var(--mirai-ui-motion-expand) var(--mirai-ui-motion-easing);
  width: 100vw;
  z-index: var(--mirai-ui-layer-M);
}

.footer.visible {
  transform: translateY(0%);
}

.footer .cartItem {
  box-shadow: inset 0 -1px 0 var(--mirai-ui-content-border);
  gap: var(--mirai-ui-space-XS);
}

.cartItem .room {
  align-items: center;
  background-color: var(--mirai-ui-content-border);
  border-radius: calc(var(--mirai-ui-border-radius) / 2);
  gap: var(--mirai-ui-space-XXS);
  height: var(--mirai-ui-space-L);
  line-height: var(--mirai-ui-space-L);
  min-width: var(--mirai-ui-space-XL);
  padding: 0 var(--mirai-ui-space-XS);
}

.cartItem .description {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.cartItem .buttonClose {
  background-color: var(--mirai-ui-content-background);
}

.footer .summary {
  gap: var(--mirai-ui-space-S);
}

.footer .totalPrice {
  flex: 1;
  gap: var(--mirai-ui-space-XXS);
}

/* -- <Unavailability> ------------------------------------------------------ */
.unavailability {
  gap: var(--mirai-ui-space-S);
}

.unavailability .banner {
  align-items: center;
  background-color: var(--mirai-ui-accent-background);
  border: solid 1px var(--mirai-ui-accent-border);
  margin: var(--mirai-ui-space-S) 0;
  border-radius: var(--mirai-ui-border-radius);
}

.unavailability .banner .texts {
  flex: 1;
  gap: var(--mirai-ui-space-XXS);
}

.unavailability .banner .title,
.unavailability .banner .icon {
  color: var(--mirai-ui-accent-dark);
}

.unavailability .banner .icon {
  align-self: center;
  font-size: var(--mirai-ui-space-XL);
  height: var(--mirai-ui-space-XL);
  width: var(--mirai-ui-space-XL);
}

.unavailability .card {
  background-color: var(--mirai-ui-base);
  border-radius: var(--mirai-ui-border-radius);
  box-shadow: 0 0 1px var(--mirai-ui-content-light);
  height: 100%;
}

.unavailability .card > *:not(.distribution) {
  padding: var(--mirai-ui-space-S) var(--mirai-ui-space-M);
}

.unavailability .card > *:not(:last-child) {
  border-bottom: solid 1px var(--mirai-ui-content-border);
}

.unavailability .card > *:nth-last-child(2) {
  flex: 1;
}

.unavailability .card .price {
  align-items: flex-end;
  justify-content: space-between;
}

.unavailability .card .distribution {
  gap: var(--mirai-ui-space-XS);
  padding: var(--mirai-ui-space-XS);
}

.unavailability .card .distribution > * {
  background-color: var(--mirai-ui-content-background);
  padding: var(--mirai-ui-space-XS);
}

.unavailability .card .anchorDates {
  margin: 0 var(--mirai-ui-space-XS);
}

.unavailability .action {
  display: inline;
}

.unavailability .action * {
  margin-right: calc(var(--mirai-ui-space-XS) / 8);
}

.unavailability .spaceBetween {
  align-items: center;
  justify-content: space-between;
}

/* -- common ---------------------------------------------------------------- */
.box {
  background-color: var(--mirai-ui-base);
  border-radius: var(--mirai-ui-border-radius);
  box-shadow: 0 0 1px var(--mirai-ui-content-light);
  padding: var(--mirai-ui-space-M);
}

.blur {
  backdrop-filter: blur(8px);
  z-index: var(--mirai-ui-layer-M);
}

.blur * {
  backdrop-filter: none;
  transform: none;
}

.error {
  align-self: center;
}

/* S */
@media only screen and (max-width: 480px) {
  .container {
    min-height: 100svh;
    padding: 0 var(--mirai-ui-space-S) var(--mirai-ui-space-M) var(--mirai-ui-space-S);
  }

  .container .content {
    gap: var(--mirai-ui-space-M);
    padding-top: var(--mirai-ui-space-M);
  }

  /* -- <Finder> ---------------------------------------------------------------------------------------------------- */
  .finder {
    background-color: var(--mirai-ui-base);
    border-bottom: solid 1px var(--mirai-ui-content-border);
    gap: var(--mirai-ui-space-XXS);
    padding: var(--mirai-ui-space-S);
  }

  /* -- <Header> ---------------------------------------------------------------------------------------------------- */
  .header .tag {
    justify-content: space-between;
    opacity: 0.1;
  }

  /* -- <RoomSelector> ---------------------------------------------------------------------------------------------- */
  .roomSelector {
    background-color: var(--mirai-ui-base);
    border-bottom: solid 1px var(--mirai-ui-content-border);
    left: 0;
    padding: var(--mirai-ui-space-S);
    position: sticky;
    right: 0;
    top: 0;
    width: auto;
    z-index: var(--mirai-ui-layer-M);
  }

  /* -- <Unavailability> ------------------------------------------------------ */
  .unavailability .banner {
    gap: var(--mirai-ui-space-S);
    padding: var(--mirai-ui-space-S);
  }

  .unavailability .texts ui-text,
  .unavailability .contactInfo {
    text-align: center;
  }

  .unavailability .button {
    width: -moz-available;
    width: -webkit-fill-available;
  }

  /* -- <Footer> ---------------------------------------------------------------------------------------------------- */
  .footer .cartItem {
    padding: var(--mirai-ui-space-XS) var(--mirai-ui-space-S);
  }

  .footer .summary {
    align-items: flex-end;
    padding: var(--mirai-ui-space-S);
  }
}

/* M & L */
@media only screen and (min-width: 481px) {
  .container {
    min-height: 66svh;
    padding: 0 var(--mirai-ui-space-M) var(--mirai-ui-space-M) var(--mirai-ui-space-M);
  }

  /* -- <Finder> ---------------------------------------------------------------------------------------------------- */
  .finder {
    backdrop-filter: blur(8px);
    padding: var(--mirai-ui-space-M);
  }

  /* -- <Header> ---------------------------------------------------------------------------------------------------- */
  .header {
    margin-bottom: var(--mirai-ui-space-XS);
    margin-top: var(--mirai-ui-space-XS);
  }

  .header > * {
    margin: var(--mirai-ui-space-XS) 0;
  }

  /* -- <Unavailability> -------------------------------------------------------------------------------------------- */
  .unavailability .banner {
    gap: var(--mirai-ui-space-M);
    padding: var(--mirai-ui-space-XL) var(--mirai-ui-space-L);
  }

  /* -- <Footer> ---------------------------------------------------------------------------------------------------- */
  .footer {
    padding: 0 var(--mirai-ui-space-M);
  }

  .footer .cartItem {
    padding: var(--mirai-ui-space-S) 0;
  }

  .footer .summary {
    padding: var(--mirai-ui-space-M) 0;
  }
}
